<template>
  <b-container>
    <b-row class="mt-4 justify-content-md-center">
      <b-col class="body" md="6">
        <div class="mt-4"> 
              <b-row class="justify-content-md-center">
                <b-col md="10">
                  <h3 class="mt-2  text-primary">Welcome!</h3>
                  <h6>MTB Ecosystem is our new online platform where you will find our research reports, data and industry insights about global innovation ecosystems and trends. </h6>
                  <h6 class="mb-3 text-muted">Get FREE access to the platform by signing up below!</h6>
                </b-col>
              </b-row>
              
              <b-row class="justify-content-md-center">
                <b-col md="8">
                  <b-form @submit.prevent="signup">
                    <b-form-group id="input-group-email" label="Email" label-for="email" description="">
                      <b-form-input id="email" v-model="form.email" type="email" required placeholder=""></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-password" label="Password" label-for="password" description="">
                      <b-form-input id="passoword" v-model="form.password" type="password" required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-privacy">
                      <b-form-checkbox-group v-model="form.checked" id="privacy" required>
                        <b-form-checkbox v-model="form.privacy" required>I Agree with the <a target="_blank" href="https://www.iubenda.com/privacy-policy/18888635">Privacy Policy</a></b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-form-group>

                    <b-button class="mt-4" block type="submit" variant="primary">Sign up</b-button>
                    <p v-if="feedback" class="text-danger mt-3">{{ feedback }}</p>
                  </b-form>

                  <p class="bold mt-3">Already registered? <router-link to="/login">Sign in here!</router-link>
                    <br/>
                    <small >For any issue, email us at   <a href = "mailto: ecosystem@mindthebridge.org">ecosystem@mindthebridge.org</a></small>
                  </p>
                </b-col>
              </b-row>
            </div>
      </b-col>
    </b-row>
    
  </b-container>
</template>

<script>
import firebase from "firebase/app";
require("firebase/firestore");

export default {
  name: "signin",
  data() {
    return {
      feedback: null,
      form: {
        email: "",
        password: "",
        privacy: false,
      },
      emailForgottenPassword: null,
    };
  },
  created() {
    if (firebase.auth().currentUser) {
      this.$router.push({ path: "/reports" });
    }
  },
  methods: {
    emailSentToaster() {
      this.toastCount++;
      this.$bvToast.toast("We've just sent you an email to complete the signup process", {
        variant: "success",
        title:"Email Verification",
        autoHideDelay: 100000,
        appendToast: false,
      });
    },

    signup() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          let user = firebase.auth().currentUser;
          var actionCodeSettings = {
            url: "https://research.mindthebridge.com/login?email=" + user.email,
          };
          user
            .sendEmailVerification(actionCodeSettings)
            .then(() => {
              // console.log("EMAIL SENT");
              // this.emailSentToaster()
              this.$nextTick(() => {
                this.hasSubmitted = true;
              });

              firebase
                .firestore()
                .collection("users")
                .doc(this.form.email.toLowerCase())
                .set({
                  email: this.form.email.toLowerCase(),
                  privacy: this.form.privacy[0],
                  id: user.uid,
                  hasCompletedSignup: false,
                  timestamp: Date.now(),
                });
            })
            .then(() => {
              // console.log("USER REGISTERED SUCCESSFULLY");
              this.$router.push({ name: "SignupEmail" });
            })
            .catch((err) => {
              this.feedback = err.message;
            });
        })
        .catch((err)=>{
          if (err.code == "auth/email-already-in-use"){
            this.feedback = err.message 
          }
        })
    },
  },
};
</script>


<style scoped>
.body{
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, .70);  
  border-radius: 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; 
}
</style>